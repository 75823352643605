import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SecondaryApp from "./SecondaryApp"; // Importuok naują aplikaciją
import reportWebVitals from "./reportWebVitals";

// Pagrindinė aplikacija
const root = ReactDOM.createRoot(document.getElementById("add_food"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Antra aplikacija
const secondaryRoot = ReactDOM.createRoot(
  document.getElementById("secondary_app")
); // Naujas root ID
secondaryRoot.render(
  <React.StrictMode>
    <SecondaryApp /> {/* Čia naujas komponentas */}
  </React.StrictMode>
);

// Jei nori matuoti veikimo našumą:
reportWebVitals();
