import React from "react";

const SecondaryApp = () => {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>Secondary App</h1>
      <p>This is a simple React component.</p>
    </div>
  );
};

export default SecondaryApp;
